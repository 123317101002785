@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.banner {
  &__carousell {
    :global {
      & .swiper-wrapper {
      }

      & .swiper-slide {
        height: unset;
      }

      & .swiper-slide-active {
        transform: scale(1);
      }

      & .swiper-pagination {
        &-bullet {
          width: 6px;
          height: 6px;
          opacity: 1;
          background-color: $gray-light;

          &-active {
            width: 20px;
            border-radius: 4px;
          }
        }
      }

      & .swiper-pagination-bullet-active {
        background-color: $pink-main;
      }
    }
  }

  &__image {
    &-wrapper {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 320px;

      @include mobile {
        height: auto;
      }

      & img {
        width: 100%;
        height: 100%;
        min-height: 120px;
        border-radius: 10px;
      }
    }
  }
}
