@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.tab-button {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 1px;
  background-color: $gray-feather;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 10px;

    @include tablet {
      margin-left: 6px;
    }

    @include mobile {
      margin-left: 6px;
    }
  }

  &--selected {
    background-color: $pink-main;

    & .tab-button__wrapper {
      background-color: white;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 6px 12px 6px 6px;
    border-radius: 100px;
    background-color: white;

    @include tablet {
      padding: 6px 18px 6px 6px;
    }

    @include mobile {
      padding: 6px 18px 6px 6px;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 100px;
    background: linear-gradient(135deg, #ff3c78 0%, #ff6b4d 100%);
    width: 32px;
    height: 32px;
    padding: 4px;

    @include tablet {
      width: 22px;
      height: 22px;
      padding: 2px;
    }

    @include mobile {
      width: 22px;
      height: 22px;
      padding: 2px;
    }
  }

  &__title {
    @include Body-Regular-M;
    color: $black;
    margin-top: 2px;
  }
}
