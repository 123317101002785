@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.live {
  min-height: 800px;
  padding: 0 0 66px 0;
  background-color: $gray-very-light;

  &__title {
    @include Body-Bold-M;
    color: $black;
    margin-top: 30px;
    margin-bottom: 20px;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    @include mobile {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-right {
    }

    &-calendar {
      &-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      &-icon {
        width: 44px;
        height: 44px;
        background-color: white;
        border-radius: 100%;
        border: 1px solid $gray-feather;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include tablet {
          width: 34px;
          height: 34px;
        }

        @include mobile {
          width: 34px;
          height: 34px;
        }

        & svg {
          width: 24px;
          height: 24px;
          fill: $black;

          @include tablet {
            width: 18px;
            height: 18px;
          }

          @include mobile {
            width: 18px;
            height: 18px;
          }
        }
      }

      &-text {
        @include Body-Regular-S;
        color: $black;
        margin-left: 6px;
      }
    }
  }
}

.container {
  @include container-v1;

  @include tablet {
    padding: 0px 10px;
  }

  @include mobile {
    padding: 0px 10px;
  }
}

.banner__wrapper {
  margin-top: 40px;

  @include tablet {
    margin-top: 20px;
  }

  @include mobile {
    margin-top: 20px;
  }
}
