@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.popular-zone-brand {
  padding-top: 40px;
  padding-bottom: 30px;
  margin-bottom: 40px;
  background-color: white;

  &__item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 98px;
    padding: 0 1px;
    text-align: center;
    cursor: pointer;

    @include desktop-only {
      &:hover {
        & .choice-brand-nav__item-image-mask {
          opacity: 0.2;
        }
      }
    }

    @include tablet {
      padding: 0 1px;
      width: 68px;
    }

    @include mobile {
      padding: 0 1px;
      width: 68px;
    }

    &-image {
      width: inherit;
      height: 100px;
      position: relative;
      border-radius: 10px;
      border: 1px solid $gray-feather;
      overflow: hidden;

      @include tablet {
        height: 68px;
      }

      @include mobile {
        height: 68px;
      }

      &-mask {
        background-color: $gray-dark;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease-out;
      }
    }

    &-title {
      @include Body-Regular-S;
      color: $black;
      margin-top: 10px;
      @include text-ellipsis;

      @include tablet {
        @include Body-Regular-XS;
      }

      @include mobile {
        @include Body-Regular-XS;
      }
    }
  }
}
