@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
$image-padding-top: 133.92857%;
$image-tablet-padding-top: 124.84848%;
$image-mobile-padding-top: 124.84848%;

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  background-color: $white;
  overflow: hidden;
  cursor: pointer;

  &-image {
    position: relative;
    width: 100%;

    // 比例: 56:75
    // 高度: 75 / 56 * 100 = 133.92857%
    padding-top: $image-padding-top;

    @include tablet {
      // 比例: 165:206
      // 高度: 206 / 165 * 100 = 124.84848%
      padding-top: $image-tablet-padding-top;
    }

    @include mobile {
      // 比例: 165:206
      // 高度: 206 / 165 * 100 = 124.84848%
      padding-top: $image-mobile-padding-top;
    }

    & img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__super-star-tag {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 89.289%; // 沒有固定公式，透過肉眼抓的比例
    padding-top: 73.664%; // 沒有固定公式，透過肉眼抓的比例
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 26px 10px 10px 10px;
    min-height: 114px;
    position: relative;
    flex: 1;
    z-index: 12;
    background-color: $white;

    &-name {
      @include Subtitle-Bold-S;
      margin-bottom: 4px;
      color: $black;
    }

    &-title {
      @include Body-Regular-S;
      @include text-ellipsis;
      color: $gray-dim;
    }

    &-time {
      @include Body-Regular-XS;
      color: $gray-light;
      margin-top: 14px;
      height: 30px;
      display: flex;
      align-items: center;

      @include tablet {
        margin-top: 7px;
      }

      @include mobile {
        margin-top: 7px;
      }
    }

    &__booking {
      width: 100%;
      margin-top: 15px;

      @include tablet {
        margin-top: 7px;
      }

      @include mobile {
        margin-top: 7px;
      }
    }

    &__header {
      flex: 1;
    }
  }

  &-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -25px;
    left: 10px;
    border: 1px solid $gray-background;
    background-color: white;

    & img {
      height: inherit;
      width: inherit;
    }

    @include tablet {
      width: 40px;
      height: 40px;
      top: -20px;

      & img {
        height: inherit;
        width: inherit;
      }
    }

    @include mobile {
      width: 40px;
      height: 40px;
      top: -20px;

      & img {
        height: inherit;
        width: inherit;
      }
    }
  }

  &-tag {
    height: 22px;
    min-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba($black, 0.4);
    border-radius: 100px;

    &.current {
      padding-left: 8px;
      padding-right: 2px;
    }

    &-status {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      margin-right: 4px;
      border-radius: 100px;

      &.past {
        color: $white;
        @include Body-Regular-XS;
        background-color: rgba($black, 0.5);
        line-height: inherit;
      }

      &.future {
        color: $white;
        @include Body-Regular-XS;
        background: linear-gradient(104deg, $blue-main 0%, #8a7cf2 100%), linear-gradient(104deg, $orange-middle 0%, $orange-main 100%);
        line-height: inherit;
      }
    }

    &-icon {
      width: 18px;
      height: 18px;
      margin-left: 2px;

      & svg {
        width: inherit;
        height: inherit;
      }
    }

    &-views {
      color: $white;
      @include Body-Regular-XS;
      line-height: inherit;
      margin-right: 8px;
    }
  }

  &-player {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;

    &.isShow {
      display: block;
    }

    &-wrapper {
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}
