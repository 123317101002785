@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.live {
  &__list {
    width: 100%;

    @include tablet {
      margin-top: 10px;
    }

    @include mobile {
      margin-top: 10px;
    }

    &-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      gap: 20px;
      overflow: auto;

      @include desktop-m {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 20px;
      }

      @include tablet {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 10px;
      }

      @include mobile {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 10px;
      }
    }
  }
}
