@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.empty {
  background-color: $white;
  border-radius: 10px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  > :not(:last-child) {
    margin-bottom: 20px;
  }

  &-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $gray-very-light;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: $pink-main;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: $black;
      @include Body-Bold-M;
      margin-bottom: 6px;
    }

    &-description {
      color: $black;
      @include Body-Regular-S;
    }
  }
}
