// shop color
$blue-main: #6458c4;
$light-purple: #aca2fa;

$purple-middle: #be42b5;

$pink-main: #ff3c78;
$pink-light: #ff98b8;
$pink-background: #ffe2eb;

$orange-middle: #ff6b4d;
$orange-main: #ff9428;

$red-error: #e60019;

$green-check: #64be3c;
$green-background: #e6f2e0;

$black: #080808;

$gray-dark: #333333;
$gray-disabled: #eaeaea;
$gray: #555555;
$gray-dim: #777777;
$gray-light: #aaaaaa;
$gray-feather: #dddddd;
$gray-background: #f0f0f0;
$gray-very-light: #f2f2f2;
$white: #ffffff;

$gold: #ffdebc;

$header-index: 9999;
$page-loading-index: 99999;
$ig-external-index: 99999;
$room-interact-index: 12;
$room-like-button-desktop: 1;
$emoji-animation-area-index: -1;
$room-mic-request-index: 11;
$room-animation-connecting-area: 1;
$room-animation-mobile-controll-item: 13;
$room-phone-action: 15;
$room-cover: 13;
$message-limit-index: 9999;
$navigation-bar-index: 9998;

$fixed-player-index: 9999;
$fixed-player-close-index: 25;
$fixed-player-enter-index: 16;
$fixed-player-end-index: 20;
$fixed-player-end-content: 30;

$player-loading: 2;
$player-play-index: 30;
$apply-return-footer-index: 10001;

$ios-url-bar-height: 80px;

// Responsive
// mobile 360 - 767
// tablet 710 - 1023
// desktop-m 1023 - 1439
// desktop 1440 up
$mobile-width: 360px;
$tablet-width: 710px; // 平板已改為 710-1023 // Reference:// https://www.figma.com/file/TfgiHscU8sYfk3DnN8LoLG/sample-WEB?node-id=0%3A1&t=JvMmTTozkrYicr7Y-0
$desktop-m-width: 1024px;
$desktop-middle-width: 1200px;
$desktop-width: 1440px;
$desktop-max-width: 1920px;

$header-height: 70px; // header
$header-mobile-height: 50px; // header mobile
$header-tip: 30px;
$header-mobile-bottom-height: 45px;
$footer-height: 168px;
$footer-mobile-height: 208px;

$chat-input-height: 40px;
$chat-input-mobile-height: 36px;

$latin-system: en-us, en-hk, tr-tr, id-id, es-pe;
$chinese-system: zh-tw, zh-hk;
$english-system: en-us, en-hk;

$shopping-cart-footer-height: 70px;
$shopping-cart-footer-coupon-height: 50px;

$promotion-selector-footer-height: 58px;
$prepared-detail-footer-height: 70px;
$coupon-promotion-detail-drawer-action: 68px;

$desktop-navigation-menu-height: 53px;
$mobile-navigation-menu-height: 42px;
$filter-tab-mobile-height: 72px;
