@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.popular-zone-desktop {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--brand {
    flex-grow: 1;
    overflow: hidden;

    & .popular-zone-desktop__header {
      background: linear-gradient(90deg, $pink-background 0%, white 100%);
    }
  }

  &--event {
    max-width: 740px;
    min-width: 740px;
    width: 100%;

    & .popular-zone-desktop__header {
      background: linear-gradient(90deg, #e8e8ff 0%, white 100%);
    }
  }

  &__header {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__icon {
    position: absolute;
    top: 2px;
    left: 0;
  }

  &__title {
    @include Body-Bold-M;
    padding-left: 50px;
    display: flex;
    align-items: center;
    height: 41px;
  }

  &__content {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
  }

  &__brand {
    padding: 20px;
    position: relative;

    &-list {
      @include tablet {
        margin: 0;
      }

      @include mobile {
        margin: 0;
      }
    }

    &-slide {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;
    }
  }

  &__event {
    display: flex;
    height: 100%;

    &-left {
      padding: 20px;
      max-width: 220px;
      background-color: #f8f8ff;
      border-bottom-left-radius: 10px;
    }

    &-right {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 10px;
      padding: 20px;
    }
  }

  :global {
    & .swiper-slide {
      max-width: 100px;
    }

    & .navigation-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.15);
      border-radius: 100%;
      cursor: pointer;
      bottom: 140px;

      @include tablet {
        width: 30px;
        height: 30px;
        bottom: 20px;
      }

      @include mobile {
        width: 30px;
        height: 30px;
        bottom: 20px;
      }

      &-prev {
        left: 20px;

        @include tablet {
          left: -3px;
        }

        @include mobile {
          left: -3px;
        }

        & svg {
          fill: $black;
          width: 20px;
          height: 20px;
        }
      }

      &-next {
        right: 20px;

        @include tablet {
          right: -3px;
        }

        @include mobile {
          right: -3px;
        }

        & svg {
          fill: $black;
          width: 20px;
          height: 20px;
          padding: 2px 5px 2px 5px;
        }
      }
    }

    & .swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
