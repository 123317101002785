@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.popular-zone-event {
  padding-top: 40px;
  padding-bottom: 30px;
  margin-bottom: 40px;
  background-color: white;

  &__item {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @include desktop-only {
      &:hover {
        & .choice-brand-nav__item-image-mask {
          opacity: 0.2;
        }
      }
    }

    @include tablet {
      padding: 0 1px;
      width: 98px;
    }

    @include mobile {
      padding: 0 1px;
      width: 98px;
    }

    &--vertical {
      flex-direction: column;
    }

    &--horizontal {
      flex-direction: row;

      & .popular-zone-event__item-price {
        margin-top: 4px;
      }
    }

    &--large {
      & .popular-zone-event__item-image {
        width: 180px;
        height: 180px;
      }
    }

    &--medium {
      padding: 10px 0;
      width: 100px;

      & .popular-zone-event__item-image {
        width: 98px;
        height: 98px;
      }
    }

    &--small {
      & .popular-zone-event__item-image {
        width: 85px;
        height: 85px;
      }
    }

    &--vertical-large {
      & .popular-zone-event__item-content {
        width: 100%;
        padding: 8px;
      }

      & .popular-zone-event__item-title {
        @include Body-Bold-M;
        color: $black;
      }

      & .popular-zone-event__item-price {
        @include Subtitle-Bold-L;
        color: $pink-main;
        margin-top: 9px;
      }
    }

    &--best-selling {
      background-color: #f8f8ff;
      border-radius: 10px;

      @include tablet {
        padding: 10px;
      }

      @include mobile {
        padding: 10px;
      }
    }

    &-image {
      position: relative;
      border-radius: 10px;
      border: 1px solid $gray-background;
      overflow: hidden;
      flex-shrink: 0;

      &-mask {
        background-color: $gray-dark;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease-out;
      }
    }

    &-content {
      padding: 12px 8px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    &-title {
      @include Body-Regular-S;
      @include text-ellipsis(2);
      color: $black;

      @include tablet {
        @include Body-Regular-S;
        @include text-ellipsis(1);
      }

      @include mobile {
        @include Body-Regular-S;
        @include text-ellipsis(1);
      }
    }

    &-price {
      @include Body-Bold-M;
      color: $pink-main;
    }
  }
}
