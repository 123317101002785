@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.popular-zone {
  margin-top: 40px;
  display: flex;
  flex-direction: row;

  @include tablet {
    margin-top: 20px;
    flex-direction: column;
  }

  @include mobile {
    margin-top: 20px;
    flex-direction: column;
  }

  & > *:not(:first-child) {
    margin-left: 20px;

    @include tablet {
      margin-left: 0;
      margin-top: 20px;
    }

    @include mobile {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
