@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  background-color: $white;
  overflow: hidden;
  cursor: pointer;

  &-image {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    z-index: 0;

    // 比例: 56:75
    // 高度: 75 / 56 * 100 = 133.92857%
    padding-top: 133.92857%;

    @include tablet {
      // 比例: 165:206
      // 高度: 206 / 165 * 100 = 124.84848%
      padding-top: 124.84848%;
    }

    @include mobile {
      // 比例: 165:206
      // 高度: 206 / 165 * 100 = 124.84848%
      padding-top: 124.84848%;
    }

    & span {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 26px 10px 10px 10px;
    min-height: 114px;
    position: relative;
    flex: 1;

    &-name {
      @include Subtitle-Bold-S;
      margin-bottom: 4px;
      color: $black;
    }

    &__header {
      flex: 1;
    }
  }

  &-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -25px;
    left: 10px;
    border: 2px solid $white;

    & span {
      height: inherit;
      width: inherit;
      position: absolute;
      top: 0;
    }

    @include tablet {
      width: 40px;
      height: 40px;
      top: -20px;

      & img {
        height: inherit;
        width: inherit;
      }
    }

    @include mobile {
      width: 40px;
      height: 40px;
      top: -20px;

      & img {
        height: inherit;
        width: inherit;
      }
    }
  }
}
