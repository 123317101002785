@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.popular-zone-mobile {
  display: flex;
  flex-direction: column;

  &--brand {
    max-width: 620px;

    @include tablet {
      max-width: initial;
    }

    @include mobile {
      max-width: initial;
    }

    & .popular-zone-mobile__header {
      background: linear-gradient(90deg, $pink-background 0%, white 100%);
    }
  }

  &--event {
    max-width: 740px;

    @include tablet {
      max-width: initial;
    }

    @include mobile {
      max-width: initial;
    }

    & .popular-zone-mobile__header {
      background: linear-gradient(90deg, #e8e8ff 0%, white 100%);
    }
  }

  &__header {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__icon {
    position: absolute;
    top: -5px;
    left: 0;
  }

  &__title {
    @include Body-Bold-M;
    padding-left: 50px;
    display: flex;
    align-items: center;
    height: 33px;
  }

  &__content {
    padding: 10px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
  }

  :global {
    & .swiper-slide {
      max-width: 120px;
    }

    & .navigation-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.15);
      border-radius: 100%;
      cursor: pointer;
      bottom: 140px;

      @include tablet {
        top: calc(50% - 20px);
        width: 40px;
        height: 40px;
      }

      @include mobile {
        top: calc(50% - 20px);
        width: 40px;
        height: 40px;
      }

      &-prev__brand,
      &-prev__event {
        left: 20px;

        @include tablet {
          left: 6px;
        }

        @include mobile {
          left: 6px;
        }

        & svg {
          fill: $black;
          width: 20px;
          height: 20px;
        }
      }

      &-next__brand,
      &-next__event {
        right: 20px;

        @include tablet {
          right: 6px;
        }

        @include mobile {
          right: 6px;
        }

        & svg {
          fill: $black;
          width: 20px;
          height: 20px;
          padding: 2px 5px 2px 5px;
        }
      }
    }

    & .swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
